<template>
    <b-breadcrumb class="bg-dark" :items="crumbs"/>
</template>
<script>
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class Breadcrumbs extends Vue {
    get crumbs() {
        return this.$store.getters.getBreadcrumbs;
    }
}
</script>
<style scoped>
    li.active {
        color: #FFF !important;
    }

    li.breadcrumb-item a {
        color: #A28058 !important;
    }
</style>
