<template>
    <div>
        <breadcrumbs/>
        <b-card class="mb-4">
            <router-view/>
        </b-card>
    </div>
</template>
<script>
import {Component, Vue} from 'vue-property-decorator';
import Breadcrumbs from '@/views/menu/breadcrumb/Breadcrumbs.vue';

@Component({
    components: {
        Breadcrumbs
    }
})
export default class Administration extends Vue {

}
</script>
<style scoped>

</style>
